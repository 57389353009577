<template>
  <div class="Progress" v-gap="['', '', '', '']">
    <Loader1A v-if="Go.isFalse(showProgress)" />
    <CircleProgress v-else :progress="progress" :label="`<div>${progress} ${showProgress} %</div>`" />
    <Spacer num=".5" v-if="Go.isFalse(showProgress)" />
    <div v-if="message || messageStore" class="ProgressMessage" v-html="messageStore || message"></div>
  </div>
</template>

<script>
export default {
  props: ["message", "showProgress"],
  components: {
    Loader1A: () => import("./Loader1A.vue"),
  },
  computed: {
    progress: function() {
      return this.$store.state.progress.percent;
    },
    messageStore: function() {
      return this.$store.state.progress.message;
    },
  },
  beforeMount: function() {
    this.$store.dispatch("resetProgress");
  },
};
</script>

<style lang="scss">
.Progress {
  .appLoader {
    position: relative;
    margin: auto;
  }

  .appLoader .myLoader {
    @include uncentered();
    margin: auto;
  }
}
</style>
